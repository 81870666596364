body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  color: #f5f5f5;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.buttons-bar {
    display: flex;
    justify-content: end;
    margin: 0.5rem 0;
}

body .bmc-btn {
  font-size: 1.6rem;
  padding: 0 11px 0 12px;
  height: 45px;
  bottom: 14px;
  right: 14px;
  min-width: 0;
  border-radius: 8px;
  opacity: 0.9;
}

body .feedback-btn {
  font-size: 1.6rem;
  padding: 0 11px 0 12px;
  height: 45px;
  bottom: 54px;
  right: 14px;
  min-width: 0;
  border-radius: 8px;
  opacity: 0.9;
}

body .bmc-btn:hover {
  opacity: 1;
}

body .bmc-btn span {
  font-size: 0.94rem;
  font-family: inherit;
  font-weight: 0;
}

svg {
  color: #fff;
}

h1 {
  font-size: 2.1rem;
  margin: 0.34em 0 0.2em;
}

h2 {
  margin: 0.3em 0;
}

p {
  line-height: 150%;
}

header {
  padding: 0.2rem 0 1.2rem;
}

.wo_subheader {
  margin: 0;
  opacity: 0.7;
  max-width: 700px;
}

.content_wrapper {
  max-width: 900px;
  margin: 0 auto;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.JavaMenu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.JavaMenu label {
  margin: 0 3px 0 auto;
}

.JavaMenu svg {
  font-size: 1.6rem;
}

.JavaMenu button svg {
  font-size: 1.4rem;
}

.JavaMenu label>span:nth-last-child(1) {
  transform: translateX(-3px);
}

.Content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Editor {
  padding: 0 0.8rem 0.5rem 0;
  box-sizing: content-box;
}

.Editor>div:nth-child(2) {
  max-width: 500px;
}

.Editor:nth-last-child(1) {
  padding-right: 0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.coffee-button-container {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.MuiFormControlLabel-label {
  user-select: none;
}

.ace_editor {
  border: 1px solid #434551;
  border-radius: 9px;
  box-shadow: 2px 2px 3px 0 #24252d;
}

.ace-monokai .ace_gutter {
  background: #363841;
  color: #72737d;
}

.ace_scroller {
  padding: 2px 0 0;
  background-color: #292a31
}

.ace_gutter>.ace_layer,
.ace_gutter {
  width: 1.8rem !important
}

.ace_scroller {
  left: 1.8rem !important;
}

.ace_folding-enabled>.ace_gutter-cell,
.ace_gutter-cell {
  width: 1.8rem;
  padding: 0 4px 0 0;
  box-sizing: border-box;
}

.ace_fold-widget {
  box-sizing: border-box;
   margin: 0 !important;
  display: none;
  width: 11px;
  vertical-align: top;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAANElEQVR42mWKsQ0AMAzC8ixLlrzQjzmBiEjp0A6WwBCSPgKAXoLkqSot7nN3yMwR7pZ32NzpKkVoDBUxKAAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 3px;
  border: 1px solid transparent;
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .Editor>div:nth-child(2) {
    width: 48vw !important;
    box-sizing: border-box;
  }

  .ace_gutter {
    width: 1.4rem !important
  }

  .ace_scroller {
    left: 1.4rem !important;
  }

  .ace_folding-enabled>.ace_gutter-cell,
  .ace_gutter-cell {
    width: 1.4rem;
    padding: 0 4px 0 0;
    box-sizing: border-box;
  }

  .Editor {
    padding: 0 0.5rem 0.5rem 0;
  }
}



@media only screen and (max-width: 600px) {
  .Editor>div:nth-child(2) {
    width: 100% !important;
  }

  .Editor,
  .Editor:nth-last-child(1) {
    padding: 0.5rem 10px;
  }

  .Content {
    flex-direction: column;
    align-content: center;
  }

  .Editor>div:nth-child(2) {
    width: 100%;
    max-width: 100%;
    max-height: 300px;
  }
}

@media only screen and (max-width: 420px) {
  h1 {
    font-size: 2.3rem;
  }

  .wo_subheader {
    padding: 0 10px;
    line-height: 170%;
  }

  body .bmc-btn {
    font-size: 1.6rem;
    padding: 0 8px;
    height: 40px;
    bottom: 5px;
    right: 5px;
  }
}